<template>
  <Table :columns="columns1" :data="data1"></Table>
</template>
<script>
export default {
  data() {
    return {
      columns1: [
        {
          title: "序号",
          width: 70,
          key: "order",
        },
        {
          title: "流水号",
          key: "num",
        },
        {
          title: "使用部门",
          key: "user",
        },
        {
          title: "购置部门",
          width: 300,
          key: "purchase",
        },
        {
          title: "当前状态",
          width: 100,
          key: "state",
        },
        {
          title: "当前办理部门",
          key: "handle",
        },
        {
          title: "申报部门",
          key: "declare",
        },
        {
          title: "创建日期",
          key: "date",
        },
      ],
      data1: [],
    };
  },
};
</script>